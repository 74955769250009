import { Icon } from '@/components/general/Icon';
import clsx from 'clsx';
import { type FC, useState } from 'react';

import type { DropdownsMobProps } from './DropdownsMob.types';

const DropdownsMob: FC<DropdownsMobProps> = ({ dropdowns }) => {
  const [isOpen, setIsOpen] = useState(-1);

  const handleOpen = (index: number) => {
    if (isOpen === index) {
      setIsOpen(-1);
    } else {
      setIsOpen(index);
    }
  };

  return (
    <div className="flex gap-24">
      {dropdowns.map(({ links, title }, index) => (
        <div className="flex flex-col gap-16" key={index}>
          <button
            onClick={() => handleOpen(index)}
            className="flex items-center gap-8 text-start text-text-primary"
          >
            {title}

            <Icon
              icon="chevronDown"
              width={20}
              className={clsx([
                'fill-text-primary',
                'transition',
                {
                  'rotate-180': isOpen === index,
                  'rotate-0': isOpen !== index,
                },
              ])}
            />
          </button>

          <ul
            className={clsx([
              'flex',
              'flex-col',
              'gap-16',
              'list-none',
              'm-0',
              'p-0',
              'transition-all',
              isOpen !== index && ['max-h-0', 'overflow-hidden', '-mt-16'],
              isOpen === index && ['py-10', 'overflow-auto'],
            ])}
          >
            {links.map(({ href, label, target }) => (
              <li key={label}>
                <a href={href} target={target}>
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export { DropdownsMob };
